import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { careerIcon1, careerIcon2, careerIcon3, careerIcon4 } from "src/assets";
import ComingSoon from "src/Components/ComingSoon";
import { getJobList } from "src/services";
import JobItem from "./component/JobItem";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import axios from "axios";
const Index = () => {
  const [dataList, setDataList] = useState<any>([]);
  const BASE_URl = process.env.REACT_APP_API_URL;
  console.log(dataList, "dataListdataListdataListdataListdataListdataListdataListdataList");
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);
  console.log(nextPageUrl, "nextPageUrlnextPageUrlnextPageUrlnextPageUrl");
  
  const [prevPageUrl, setPrevPageUrl] = useState<string | null>(null);


  
  
 

  
  const fetchJobs = async (page: number) => {
    try {
      const response = await axios.get(
        `${BASE_URl}/jobs?page=${page}`
      );
      if (response.data) {
        setDataList(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setNextPageUrl(response.data.next_page_url);
        setPrevPageUrl(response.data.prev_page_url);
      }
    } catch (error) {
      console.log("Error fetching jobs: ", error);
    }
  };

  useEffect(() => {
    fetchJobs(currentPage);
  }, []);

  const handleNext = () => {
    if (nextPageUrl) {
      fetchJobs(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (prevPageUrl) {
      fetchJobs(currentPage - 1);
    }
  };


  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href="https://ditinustechnology.com/career"
        />
        <title>Careers at Ditinus Technology | Join Our Innovative Team</title>
        <meta
          name="description"
          content="Build your career with Ditinus Technology. Discover open positions and join a passionate team dedicated to IT excellence and digital transformation. Apply now!"
        />
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo blog-list1">
        <section className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 text-center top-banner">
              <h1 className="heading-01">Career</h1>
              <p className="p-text">
                At Ditinus Technology, we are passionate about innovation and
                excellence. We believe that our success is driven by the
                talented and dedicated people who make up our team. If you are
                eager to be part of a forward-thinking company that values
                creativity and collaboration, we want to hear from you!
              </p>
            </div>
          </div>
        </section>
        <section className="our-core mb-0">
          <div className="container">
            <div className="row mt-2">
              <h2 className="heading-02 lh-sm text-center text-WH">
                Our Core Values
              </h2>
            </div>
            <div className="row">
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img loading="lazy" src={careerIcon1} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Growth Mindset</h5>
                  <p className="text-WH p-text">
                    We grow by accepting challenges that stretch us outside of
                    our comfort zone. These experiences shape us, and we come
                    out of them stronger.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img loading="lazy" src={careerIcon2} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">
                    Lead With Empathy
                  </h5>
                  <p className="text-WH p-text">
                    We work from a place of empathy first in everything we do.
                    It’s how we build our product, support our customers, and
                    treat each other here at Ditinus.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img loading="lazy" src={careerIcon3} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Dream Big</h5>
                  <p className="text-WH p-text">
                    We’ve made it this far because we dream big! We talk about
                    “big hairy audacious goals”, formulate plans to achieve
                    them, and support each other along the way.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img loading="lazy" src={careerIcon4} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Push The Pace</h5>
                  <p className="text-WH p-text">
                    We play to win. That means raising our standards, increasing
                    velocity, narrowing our focus and pushing the pace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {dataList?.length > 0 && (
          <section className="open-roles">
            <div className="container mt-5">
              <div className="row mt-2 text-center">
                <h2 className="heading-02 lh-sm text-WH">Open Roles</h2>
                <h4 className="heading-08">Join our Talent Community</h4>
              </div>
              {dataList?.length > 0 &&
                dataList?.map((item: any, index: number) => (
                  <JobItem key={index} job={item} />
                ))}
            </div>
            <nav aria-label="Page navigation" className="d-flex justify-content-center mt-5">
        <ul className="pagination">
          {/* Previous Button */}
          <li className={`page-item ${!prevPageUrl ? "disabled" : ""}`}>
            <button className="page-link" onClick={handlePrevious} disabled={!prevPageUrl}>
              Previous
            </button>
          </li>

          {/* Page Number Display */}
          <li className="page-item">
            <span className="page-link">
              Page {currentPage} of {lastPage}
            </span>
          </li>

          {/* Next Button */}
          <li className={`page-item ${!nextPageUrl ? "disabled" : ""}`}>
            <button className="page-link" onClick={handleNext} disabled={!nextPageUrl}>
              Next
            </button>
          </li>
        </ul>
      </nav>
          </section>
        )}
      </main>
    </div>
  );
};

export default Index;
