import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { behance1, logo, logoFooter, Upwork } from "src/assets";
import "./style.css";
const Footer = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const [isBlogs, setIsBlogs] = useState<boolean>(false);
  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    console.log(response);

    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <footer className="overflow-hidden">
      <div className="container">

        <Link to="/" className="footer-show-content">
          <img
            src={logoFooter}
            alt="logo"
            data-aos-duration={1000}
            data-aos="fade-right"
            style={{ width: "100%", maxWidth: 600, height: "auto" }}
          />
        </Link>
        <div data-aos-duration={1000} data-aos="fade-left" className="footer-show-content">
          <div className="d-flex justify-content-start justify-content-lg-end gap-3 ">
            <Link to="https://www.instagram.com/ditinus/?hl=en">
              <i className="bi bi-instagram fs-2 me-1 py-1" />
            </Link>
            <Link to="https://www.facebook.com/ditinus/">
              <i className="bi bi-facebook fs-2 me-1 py-1" />
            </Link>
            <Link to="https://www.linkedin.com/company/ditinus-technology-pvt-ltd/?originalSubdomain=in">
              <i className="bi bi-linkedin fs-2 me-1 py-1" />
            </Link>
          </div>
        </div>
        <div
          className="d-flex  justify-content-between flex-wrap mt-1 mt-lg-0 " data-aos-duration={1000}
          data-aos="fade-right"
        >
          <div className="row  p-0 m-0 footer-container1 col-12 col-md-12 col-lg-7">
            <div className="row col-md-5 d-flex gap-md-3 col-12 p-0 m-0">

              <div className="col-md-5 col-12 p-0 m-0 ">
                <h5 className="footer-link-headers">Company</h5>
                <ul className="list-unstyled">
                  <li className="ps-2 ps-md-0">
                    <Link
                      className="text-decoration-none transition"
                      to="/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li className="ps-2 ps-md-0">
                    <Link
                      className="text-decoration-none transition"
                      to="/6-months-industrial-training-mohali"
                    >
                      Training
                    </Link>
                  </li>

                  <li className="ps-2 ps-md-0">
                    <Link
                      className="text-decoration-none transition"
                      to="/career"
                    >
                      Career
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-5 col-12 p-0 m-0 ">
                <h5 className="footer-link-headers">Services </h5>
                <ul className="list-unstyled p-0">
                  <li className="ps-2 ps-md-0">
                    <a
                      className="text-decoration-none transition"
                      href="/ui-ux-design-company-india"
                    >
                      UI UX
                    </a>
                  </li>
                  <li className="ps-2 ps-md-0">
                    <a
                      className="text-decoration-none transition"
                      href="/software-development-company-india"
                    >
                      Development
                    </a>
                  </li>
                  <li className="ps-2 ps-md-0">
                    <a
                      className="text-decoration-none transition"
                      href="/digital-marketing-company-india"
                    >
                      Digital Marketing
                    </a>
                  </li>
                  <li className="ps-2 ps-md-0">
                    <a
                      className="text-decoration-none transition"
                      href="/graphic-design-agency-india"
                    >
                      Graphics
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className=" col-md-4 col-12 p-0 m-0  ">
              <h5 className="footer-link-headers">Outsource With Us</h5>
              <ul className="list-unstyled p-0 ">
                <li className="ps-2 ps-md-0">
                  <a
                    className="text-decoration-none transition mb-2"
                    href="/business-process-services"
                  >
                    Business Process Services
                  </a>
                </li>
                <li className="ps-2 ps-md-0">
                  <a
                    className="text-decoration-none transition"
                    href="/hire-a-developer"
                  >
                    Hire A Developer
                  </a>
                </li>
              </ul>
            </div>
            {isBlogs && (
              <div className=" col-md-3 col-12 col-sm-12 p-0 m-0 px-md-0 px-lg-3 px-xl-0 ">
                <h5 className="footer-link-headers">Resources</h5>
                <ul className="list-unstyled p-0">
                  {/* <li>
                  <a
                    className="text-decoration-none transition"
                    href="/case-study"
                  >
                    Case Studies
                  </a>
                </li> */}
                  {/* <li>
                  <a className="text-decoration-none transition" href="/">
                    Recent Projects
                  </a>
                </li> */}
                  <li className="ps-2 ps-md-0">
                    <a
                      className="text-decoration-none transition"
                      href="/blogs"
                    >
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className=" footer-container2 col-12 col-md-12 col-lg-5 mt-2 mt-lg-0" data-aos-duration={1000}
            data-aos="fade-left" >
            <h5 className="d-flex flex-column p-0 m-0" data-aos-duration={1000}
              data-aos="fade-left">GOT A PROJECT IN MIND ?</h5>
            <div data-aos-duration={1000}
              data-aos="fade-left">
              <div className="icon-box d-flex align-items-center">
                <div className="d-flex flex-column ">
                  <h1 className="text2 p-0 m-0">
                    Let’s Talk
                  </h1>
                </div>
              </div>
              <div className="d-flex  align-items-center  gap-5">
                <Link
                  className="d-flex  align-items-center gap-2"
                  to="https://www.upwork.com/agencies/1293525604405526528/"
                >
                  <img width="40px" src={Upwork} />
                  <p className="p-0 m-0">Upwork</p>
                </Link>

                <Link
                  className="d-flex  align-items-center gap-2"
                  to="https://www.behance.net/ditinus"
                >
                  <img width="40px" src={behance1} />
                  Behance
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="p-1" style={{ opacity: '1' }} />
        <div
          className="d-flex flex-wrap justify-content-between"  >

          <Link to="/" className="footer-hide-content col-12 col-lg-7 col-md-6">
            <img
              src={logoFooter}
              alt="logo"
              data-aos-duration={1000}
              data-aos="fade-right"
              style={{ width: "100%", maxWidth: 600, height: "auto" }}
            />
          </Link>

          <div className="d-flex flex-column mt-md-2 col-12 col-lg-5 col-md-5 pb-md-4 pb-2"
          // data-aos-duration={1000} 
          // data-aos="fade-left"
          >
            <div className="d-flex justify-content-start justify-content-md-end footer-hide-content gap-md-5 gap-3 gap-lg-3">
              <Link to="https://www.instagram.com/ditinus/?hl=en">
                <i className="bi bi-instagram fs-2 me-1 ps-lg-3" />
              </Link>
              <Link to="https://www.facebook.com/ditinus/">
                <i className="bi bi-facebook fs-2 me-1 ps-lg-3" />
              </Link>
              <Link to="https://www.linkedin.com/company/ditinus-technology-pvt-ltd/?originalSubdomain=in">
                <i className="bi bi-linkedin fs-2 me-1 ps-lg-3" />
              </Link>
            </div>
            <div className="d-flex flex-row  justify-content-between justify-content-md-end gap-2 gap-sm-2 gap-lg-2 gap-xl-4 w-100  ">
              <Link to="/contact-us"><p className="footer-links text-nowrap p-0 m-0 ">
                Contacts
              </p>
              </Link>
              <Link to="/privacy-policy"><p className="footer-links text-nowrap p-0 m-0 ">
                Privacy Policy
              </p>
              </Link>
              <Link to="/terms-conditions"><p className="footer-links text-nowrap p-0 m-0 ">
                Terms & Refund Policy
              </p>
              </Link>
            </div>
            <div className="d-flex justify-content-start justify-content-md-end mt-1">
              <small>
                © <span className="text-denim">Ditinus</span> All Right
                Reserved.
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
