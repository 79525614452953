import Slider from "react-slick";
import ScreenshotsDeck from "../../Components/ScreenDeck";
import {
  adobe_xd_icon,
  Bootstrap_logo,
  bottomline,
  client,
  clientapproachicon,
  customizedicon,
  digitalmarketingicon,
  DitinuslogoBg,
  // Dot,
  dotnet,
  Evgeny,
  expertsleverageicon,
  expertteamicon,
  Founder,
  // figma_logo_icon,
  // Google,
  htmllogo,
  javascriptlogo2,
  Laravellogo,
  Nodejs_logo,
  PartnersLogo,
  phplogo,
  provenresultsicon,
  quote,
  reactlogo2,
  softdevelopmenticon,
  transparentreporticon,
  webdesignicon,
  wordpresslogo2,
} from "../../assets";
import { Helmet } from "react-helmet";
import "./home.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Particles from "./test";
import "./home.css";
// servicesContentBox
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1595,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1281,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1318,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1486,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },

    {
      breakpoint: 1628,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },

    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 866,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
  ],
};

// const settings2 = {
//   dots: false,
//   infinite: true,
//   speed: 20000,
//   slidesToShow: 7,
//   slidesToScroll: 7,
//   arrows: false,
//   autoplay: true,
//   autoplaySpeed: 100,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         dots: false,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 2000,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         dots: false,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 2000,
//       },
//     },
//   ],
// };

const settings3 = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        infinite: true,
      },
    },
  ],
};

// const settings4 = {
//   dots: false,
//   infinite: false,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 3,
//   arrows: false,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         dots: false,
//         infinite: true,
//       },
//     },
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         dots: true,
//         infinite: true,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         dots: true,
//         infinite: true,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         dots: true,
//         infinite: true,
//       },
//     },
//   ],
// };

const Home = () => {
  const [color, setColor] = useState("#ffffff");
  return (
    <main>
      <Helmet>

        <link rel="canonical" href="https://ditinustechnology.com" />
        <title>
          No. 1 Software Development Agency in India- Ditinus Technology
        </title>
        <meta
          name="description"
          content="Delivering the best software development & business process outsourcing to help businesses streamline operations & drive growth with tailored solutions."
        />


      </Helmet>

      <Particles
        className="position-absolute h-100 w-100 inset-0"
        quantity={100}
        ease={100}
        color={color}
        refresh
      />
      <section id="home" className="hero-section bg-img">
        <div className="ditinus-loop-text text-center ">
          <span>
            <img loading="lazy" src={DitinuslogoBg} alt="logo" />
          </span>
        </div>
        <div className="container h-100 position-relative">
          <div className="row align-items-end mt-0 mt-lg-0 mt-xl-5 mt-xxl-0 mt-sm-0 mt-md-0 justify-content-center h-100">
            <div className="col-12 text-center d-flex flex-column align-items-center">
              <h1 className="Main-Heading1 text-WH fw-medium mb-3 text-center lh-base">
                <strong className="text-white Main-Heading2">
                  Powerful IT Solutions For
                  <br />
                </strong>
                Accelerated Business Success!
              </h1>
              <p className="text-WH text-center col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 Main-Hero-Desc">
                Push through the complexities of the digital dilemma as we take
                you across innovative strategies, advanced technology, and
                personalized solutions tailored to your needs.
              </p>
              <div className="d-flex flex-row justify-content-center hero-banner-btns">
                <a
                  type="button"
                  href="#contact-form"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3" />
                </a>
                <Link
                  type="button"
                  to="/contact-us"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn"
                >
                  Quick Contact
                </Link>
              </div>
              <div className="hero-banner-media">
                <ul className="m-0 p-0 list-unstyled m-0 p-0 d-flex justify-content-center">
                  <li style={{ zIndex: 999999 }}>
                    <Link
                      to="https://wa.me/+919915578189"
                      className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
                    >
                      <i className="bi bi-whatsapp" />
                    </Link>
                  </li>
                  <li style={{ zIndex: 999999 }}>
                    <Link
                      to="tel:+919915578189"
                      className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
                    >
                      <i className="bi bi-telephone-fill " />
                    </Link>
                  </li>
                  <li style={{ zIndex: 999999 }}>
                    <Link
                      to="mailto:business@ditinustechnology.com"
                      className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
                    >
                      <i className="bi bi-envelope-fill" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-work-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
              <div className="d-flex gap-1 flex-column align-items-center">
                <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                  6 <span className="d-flex font-bold figure-symbol">+</span>
                </h4>
                <p className="text-WH text-uppercase text-center margin-right-2 figure-description  pe-4">
                  Years in Business
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
              <div className="d-flex gap-1 flex-column align-items-center ">
                <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                  1350 <span className="d-flex font-bold figure-symbol">+</span>
                </h4>
                <p className="text-WH text-uppercase text-center margin-right-2 figure-description   pe-4">
                  Projects Completed
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
              <div className="d-flex gap-1 flex-column align-items-center ">
                <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                  1.5k <span className="d-flex font-bold figure-symbol">+</span>
                </h4>
                <p className="text-WH text-center text-uppercase figure-description  pe-4">
                  Keywords ranked on first page
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6  col-6 col-sm-6 ">
              <div className="d-flex gap-1 flex-column align-items-center">
                <h4 className="heading-01-figures text-uppercase text-WH d-flex ">
                  $5M<span className="d-flex font-bold figure-symbol">+</span>
                </h4>
                <p className="text-WH  text-center text-uppercase  figure-description  pe-4">
                  Managed in ad spent
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  About Us
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-start align-items-center">
              <h4
                className="fw-bold heading-08 text-WH"
                style={{ lineHeight: "28px" }}
              >
                Innovative IT Solutions in India <br />
                <span className="fw-normal">
                  Our Story, Our Zeal, Our Promise !
                </span>
              </h4>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-center align-items-center">
              <p className="p-text" style={{ fontSize: "16px" }}>
                Unlock the potential for fast business growth with the best IT
                company in India. Discover how we work to take your business
                Forward!
              </p>
            </div>
          </div>
          <div className="row justify-content-between align-items-center about-img-content">
            <div className="col-12 col-xl-6 col-xxl-5 col-lg-6 col-md-9   ">
              <ScreenshotsDeck />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 ">
              <h5 className="heading-05 text-denim fw-bold">
                Turning awesome Ideas Into Reality!
              </h5>
              <h4 className="heading-06 fw-bold">
                Stay Ahead in The Digital Realm
                <br />
                <span className="fw-normal">Turn Ideas Into Reality!</span>
              </h4>
              <p className="text-WH mt-4 ">
                Staying ahead of the competition in today’s digital era is
                crucial for propelling fast success. But with so much
                competition online, how can you ensure your business stands out
                and becomes the talk of the town?
                <br /> Shall we cut to the chase? we’re not exactly
                mathematicians over here, but we’re experts at solving the
                equation of success for our clients! Break down the equation of
                your business growth with the best IT company in India!
              </p>
              {/* <Link
                to="#"
                className="px-4 py-2 transition text-WH bg-pink text-nowrap rounded-pill fill-btn outline-btn "
              >
                Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="services-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10 col-12 text-center">
              <h2 className="heading-02 lh-sm text-center text-WH">
                Our{" "}
                <span
                  className="position-relative d-inline-block"
                  // style={{ paddingLeft: "8px" }}
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Services
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100 object-fit-contain"
                  />
                </span>
              </h2>

              <h4 className="heading-08">
                Our Core IT Services For Maximum Digital Impact.
              </h4>
              <p className="text-WH p-text fw-normal">
                Leverage the power of innovative IT solutions and precisely
                reach your target audience with the best IT company in India.
              </p>
            </div>
          </div>
          <div className="row serviceBox-Contain">
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img loading="lazy" src={webdesignicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">Design</h5>
                <p className="text-WH p-text">
                  Break away from the ordinary! Our web design company in India
                  is the perfect partner for crafting user-oriented and friendly
                  interfaces that captivate your audiences.
                </p>
                <Link
                  to="/graphic-design-agency-india"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </Link>
              </div>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img loading="lazy" src={softdevelopmenticon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">Development</h5>
                <p className="text-WH p-text">
                  Experience the magic of cutting-edge solutions with our
                  exceptional software development services in India. From
                  responsive design to seamless functionality, we deliver
                  websites that captivate your audience.
                </p>
                <Link
                  to="/software-development-company-india"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </Link>
              </div>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img loading="lazy" src={digitalmarketingicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">Digital Marketing</h5>
                <p className="text-WH p-text">
                  Step into the spotlight and get your business known among the
                  crowd with the best digital marketing company in India. Our
                  carefully planned strategies, from SEO and PPC to Social Media
                  Marketing, ensure tangible outcomes for your business in the
                  long run.
                </p>
                <Link
                  to="/digital-marketing-company-india"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </Link>
              </div>
            </div>
            {/* <div
              className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img    loading="lazy" src={bpoicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">KPO/BPO Services</h5>
                <p className="text-WH p-text">
                  Get the most out of our business with our expert KPO/BPO
                  services in India. From effectively managing the data to
                  seamless customer support, we provide efficient outsourcing
                  solutions tailored to your needs.
                </p>
                <a
                  href="#"
                  target="_blank"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </a>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 text-center d-flex justify-content-center mt-3">
              <Link
                to="/services"
                type="button"
                className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Our Services{" "}
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="whyChooseUs-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Why Choose Us
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-start align-items-center">
              <h4
                className="fw-bold heading-08 py-3"
                style={{ lineHeight: "28px" }}
              >
                Why Choose Ditinus Technology as <br />
                <span className="fw-normal">
                  Your Go-To IT Company in India?
                </span>
              </h4>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <p className="text-white fs-6 fw-lighter">
                We won’t boast about ourselves. Instead, we’ll show you how we
                drive your business growth with our expertise and cutting-edge
                technology. Let’s simplify success together.
              </p>
            </div>
          </div>
          <div className="row mt-4 design-text whyChooseUs-cards">
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={expertteamicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Expert Team</h5>
              <p className="text-WH p-text">
                Our Team of experienced professionals stays ahead of the curve
                with the latest trends and best practices.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={customizedicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Customized Strategies
              </h5>
              <p className="text-WH p-text">
                Our IT company in India takes the time to identify the goals and
                objectives of your brand and leverage the latest technologies
                and trends.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={provenresultsicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Proven Results</h5>
              <p className="text-WH p-text">
                We have a track record of delivering measurable results, Helping
                businesses grow their online presence.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={transparentreporticon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Cutting-Edge Technology
              </h5>
              <p className="text-WH p-text">
                We use cutting-edge technologies needed to stay ahead. Leverage
                the power of innovation with the best IT company in India!
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={clientapproachicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Client-Centric Approach
              </h5>
              <p className="text-WH p-text">
                We take the time to understand your needs and customize plans
                accordingly, deemed the most suitable for maximum growth.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={expertsleverageicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Reliability</h5>
              <p className="text-WH p-text">
                Our IT company in India is all about leaving our customers
                satisfied with long-term and tangible outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="logoSlider-section logoSlider-aboutPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="heading-02 lh-sm text-center text-WH">
                The Things{" "}
                <span
                  className="position-relative d-inline-block"
                  style={{ marginLeft: "5px" }}
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  We Do Best
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-8 col-lg-12 mt-3 text-center">
              <p
                className="text-white fs-5 "
                data-aos="fade-up"
                data-aos-duration={900}
                style={{ fontWeight: 500 }}
              >
                Our Areas of Expertise
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center code-logo-slider">
            <Slider {...settings3}>
              <div>
                <img
                  className="mx-auto"
                  src={Laravellogo}
                  alt="LARAVEL LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={500}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={htmllogo}
                  alt="HTML LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={900}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={phplogo}
                  alt="PHP LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1300}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={wordpresslogo2}
                  alt="WORDPRESS LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1600}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={reactlogo2}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={adobe_xd_icon}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Bootstrap_logo}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={dotnet}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Nodejs_logo}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={javascriptlogo2}
                  alt="JAVASCRIPT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={2100}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Laravellogo}
                  alt="LARAVEL LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={500}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={htmllogo}
                  alt="HTML LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={900}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={phplogo}
                  alt="PHP LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1300}
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="testimonial-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <h2 className="heading-22 lh-sm text-WH d-flex justify-content-center">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Our Testimonials
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-8 col-lg-12 mt-2">
              <p
                className="p-text fw-medium text-white text-center"
                data-aos="fade-up"
                data-aos-duration={900}
              >
                We don’t just follow the latest trends – we set them. We’re not
                afraid to take risks, <br />
                and we’re always willing to try new things in order to help our
                clients stand <br />
                out in a crowded marketplace.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid m-0 p-0">
          <div className="row">
            <div className="testimonial testimonial-slider ">
              <div className="container-fluid">
                <div className="row">
                  <div className="testimonial  testimonial-slider">
                    <Slider {...settings}>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img loading="lazy" src={Founder} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img loading="lazy" src={quote} alt="img" />
                            </div>
                            <small className="review-description1">
                              Our decision to get Ditinus Technology on our team
                              was definitely a fruitful one. They kept us
                              updated with their daily progress while working on
                              the project and always implemented the feedback
                              from our end, in the most accurate way ever. The
                              designed work by them truly represents our brand
                              and the website exceeded our expectations as well.
                              Will love to work together again. We highly
                              recommend them! And gonna continue for new
                              projects.
                            </small>
                            <div className="review-title ">
                              <hr />
                              <div>
                                <h5 className="review-title1">
                                  Jai, Founder of Fruits Care Agro
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img loading="lazy" src={Evgeny} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img loading="lazy" src={quote} alt="img" />
                            </div>
                            <small className="review-description1">
                              Had a very positive experience with the
                              development of a sophisticated project! The team
                              was highly professional, and the communication was
                              excellent. Highly recommend!
                            </small>
                            <div className="review-title ">
                              <hr />
                              <div>
                                <h5 className="review-title1">
                                  Evgeny, CTO of Picom Software System Ltd
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img loading="lazy" src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img loading="lazy" src={quote} alt="img" />
                            </div>
                            <small className="review-description1">
                              Working with Ditinus was a game-changer for our
                              business! They developed a stunning, user-friendly
                              website that perfectly captures the essence of our
                              travel brand. But the real magic happened with the
                              custom CRM they built for us—it's streamlined our
                              operations, improved customer management, and
                              significantly boosted our efficiency. Their team
                              understood our needs from day one and delivered
                              beyond our expectations. We couldn't be happier
                              with the results!
                            </small>
                            <div className="review-title ">
                              <hr />
                              <div>
                                <h5 className="review-title1">
                                  John Doe, CEO of Adventure Horizons Travel
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logoSlider-section logoSlider-aboutPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-5">
              <h2 className="heading-02 lh-sm text-center text-WH">
                <span
                  className="position-relative d-inline-block"
                  style={{ marginLeft: "5px" }}
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Our Channel Partners
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center code-logo-slider">
              {/* <Slider {...settings4}> */}
              <div>
                <img
                  className="mx-auto"
                  src={PartnersLogo}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              {/* </Slider> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
