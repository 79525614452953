import Slider from "react-slick";
import { MouseParallax } from "react-just-parallax";
import {
  adobe_xd_icon,
  Bootstrap_logo,
  bottomline,
  bpoicon,
  creativesimg,
  digitalmarketingicon,
  dotnet,
  dynamicsolutionsicon,
  expertiseicon,
  friendsimg,
  htmllogo,
  // htmllogo2,
  Innovatorsimg,
  javascriptlogo2,
  Laravellogo,
  latesttechnologyicon,
  Nodejs_logo,
  onlinestore,
  phplogo,
  premiumresouresicon,
  prioritizeicon,
  reactlogo2,
  softdevelopmenticon,
  timelydeliveryprojectsicon,
  uiuxicon,
  webdesignicon,
  wordpresslogo2,
} from "src/assets";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Index = () => {
  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://ditinustechnology.com/services" />
        <title>Software Development, UI/UX, Graphic Design & Digital Marketing Services</title>
        <meta
          name="description"
          content="Ditinus Technology offers expert software development, UI/UX design, graphic design, & digital marketing services to help businesses thrive. Elevate your brand with cutting-edge technology & creative solutions. Contact us today!"
        />
        <meta
          name="keywords"
          content="Software Development Services, UI UX Services, Graphic Design Services, Digital Marketing Services"
        />
      </Helmet>
      <main>
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8 col-md-12 col-12 text-center">
                <h1 className="heading-01">Services</h1>
                <p className="p-text18 m-0 mt-3">
                  We Go Beyond Just Business! Crafting the Digital Magic So Your
                  Business Grows to New Heights.
                </p>
                {/* <p className="heading-05">What we can do&nbsp;for you.</p> */}
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-5">
                <a
                  href="#contact-form"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                <h4 className="heading-05">
                  Diverse Solutions, Detailed Expertise, Tangible Results.
                </h4>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <h2 className="p-text18">What We Can Do For You?</h2>
                <p className="p-text col-12">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row faq-content-box-row">
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img loading="lazy" src={uiuxicon} alt="icon" />
                    <h6 className="heading-08 ms-2">UI/UX</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Are you curious about how great design can help you break
                    into new markets? Our web designing company in India crafts
                    solutions that put your users first. Great design goes far
                    beyond just the aesthetic appeal of your website, it
                    compiles the essential elements of intuitive layouts to
                    seamless interaction. Our <Link to="/ui-ux-design-company-india">UI/UX services in India</Link>  deliver
                    designs that captivate and delight your users, ultimately
                    driving success for your business.
                  </p>
                </div>
              </div>
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img loading="lazy" src={softdevelopmenticon} alt="icon" />
                    <h6 className="heading-08 ms-2">Development</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    The best way to enter new markets is to introduce features
                    never known before! And we at the <Link to="/software-development-company-india">best software development company in India</Link> {" "}
                    help you enter the market by creating functional, trendy,
                    and user-friendly applications.
                  </p>
                </div>
              </div>
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img loading="lazy" src={digitalmarketingicon} alt="icon" />
                    <h6 className="heading-08 ms-2">Digital Marketing</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    To bring your ideas to life, you need to captivate your
                    target audience. What better way to do it than with our
                    top-notch <Link to="/digital-marketing-company-india"> digital marketing services in India</Link>? Our expert
                    marketers craft personalized digital solutions keeping your
                    brand’s goals and objectives in mind. With our optimized
                    tactics and expert strategies, your brand is guaranteed to
                    leave a lasting impression and captivate your audience.
                  </p>
                </div>
              </div>
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img loading="lazy" src={webdesignicon} alt="icon" />
                    <h6 className="heading-08 ms-2">Graphics Design</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Let your design speak for your brand. From sleek logos to
                    captivating website visuals, <Link to="/graphic-design-agency-india"> graphic design services</Link> focus
                    on amplifying your brand’s essence, giving it a voice
                    through striking and impactful visuals. We ensure that each
                    design reflects your brand's identity while leaving a
                    lasting impression on your audience.
                  </p>
                </div>
              </div>
              {/* <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img loading="lazy" src={bpoicon} alt="icon" />
                    <h6 className="heading-08 ms-2">KPO/BPO</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Enhance your business efficiency and outcomes with our
                    tailored BPO and KPO services in India.
                    <br />
                    Our experienced team streamlines processes, reduces costs,
                    and drives success, ensuring tangible results in the long
                    run. Whether you need in-depth market research or
                    data-driven strategies, we customize our services to meet
                    your unique needs.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="whytechnology-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12 text-center">
                <h2 className="heading-06">
                  Why Choose Ditinus Technology&nbsp;?
                </h2>
                <p className="text-gray">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      loading="lazy"
                      src={expertiseicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">Expertise</h5>
                  <p className="text-gray text-center">
                    With years of delivering successful projects and a satisfied
                    clientele, Ditinus Technology ranks among the best IT
                    companies in India. We bring a wealth of knowledge and
                    technical know-how to every project, meeting the unique
                    needs of your business.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={dynamicsolutionsicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Dynamic Solutions
                  </h5>
                  <p className="text-gray text-center">
                    The realm of technology keeps changing, bringing with it the
                    need to evolve and adjust to the dynamic needs of the
                    digital landscape. At the best IT company in India, we adapt
                    our strategies to meet the evolving requirements and diverse
                    preferences, ensuring tailored solutions for every client.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      loading="lazy"
                      src={prioritizeicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Prioritize You First
                  </h5>
                  <p className="text-gray text-center">
                    Your satisfaction and success are the top priority of our IT
                    company in India and that is why we are dedicated to
                    understanding your goals and objectives before crafting
                    perfection.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={premiumresouresicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Premium Resources
                  </h5>
                  <p className="text-gray text-center">
                    Our in-house experienced developers, with a track record of
                    serving Fortune companies, offer unparalleled value to your
                    development needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={latesttechnologyicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Latest Technology
                  </h5>
                  <p className="text-gray text-center">
                    For a stunning business presence, you need to lay hands on
                    the best technology to make an impact on your audience. With
                    the best IT company in India, your business experiences
                    transformation with the latest technologies and tools
                    delivering efficient, effective, and scalable solutions that
                    drive business growth.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={timelydeliveryprojectsicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Timely Delivery of Projects
                  </h5>
                  <p className="text-gray text-center">
                    We understand the importance of deadlines and are committed
                    to delivering projects on time and within budget. With the
                    best IT company in India, you get nothing but the best for
                    your business!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whytechnology-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12 text-center">
                <h2 className="heading-06">We Go Beyond Just Business!</h2>
                <p className="text-gray">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      loading="lazy"
                      src={creativesimg}
                      alt="img"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-02 text-WH text-center">Creatives</h5>
                  <p className="p-text18 text-center">
                    Partner with innovative minds who bring fresh perspectives
                    to your IT projects.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      loading="lazy"
                      src={Innovatorsimg}
                      alt="img"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-02  text-WH text-center">
                    Innovators
                  </h5>
                  <p className="p-text18 text-center">
                    Rely on our inventive approach to drive transformative
                    solutions for your IT needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      loading="lazy"
                      src={friendsimg}
                      alt="img"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-02  text-WH text-center">Friends</h5>
                  <p className="p-text18 text-center">
                    Experience a supportive partnership where your goals become
                    our shared mission.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="build-awesome-section">
          <div className="container">
            <div className="row position-relative justify-content-between">
              <h4 className="awesome-bg-text">awesome</h4>
              <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                <div className="move-img2">
                  <MouseParallax enableOnTouchDevice isAbsolutelyPositioned>
                    <img loading="lazy" src={onlinestore} alt="img" />
                  </MouseParallax>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                <h2 className="heading-06">We Build Awesome!&nbsp;</h2>
                <h4 className="p-text18 lh-lg">
                  We understand the unique needs of our clients and their users,
                  tailoring our strategies to meet their specific requirements,
                  ensuring excellence every step of the way.
                </h4>
                <ul className="mt-5  text-WH">
                  <li>
                    Trust us for reliable, top-notch solutions that prioritize
                    your satisfaction.
                  </li>
                  <li>
                    Benefit from our seasoned team’s expertise in addressing
                    diverse IT requirements.
                  </li>
                  <li>
                    We tailor our services to match your unique IT needs
                    effectively.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <h2 className="heading-06 lh-sm text-center text-WH">
                  The Things{" "}
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mt-4 text-center">
                <p
                  className="text-white fs-5 "
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings3}>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={wordpresslogo2}
                    alt="WORDPRESS LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1600}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={reactlogo2}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={adobe_xd_icon}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Bootstrap_logo}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={dotnet}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Nodejs_logo}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={javascriptlogo2}
                    alt="JAVASCRIPT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={2100}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
